import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cb60e508 = () => interopDefault(import('../pages/accept-invitation.vue' /* webpackChunkName: "pages/accept-invitation" */))
const _b308aed4 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _8e46ecdc = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _324c3c2a = () => interopDefault(import('../pages/days-off.vue' /* webpackChunkName: "pages/days-off" */))
const _1c2e9bd0 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _aac667fa = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _595a899a = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _34538232 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2202a67c = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _109b28ee = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _c149c150 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _04ead90a = () => interopDefault(import('../pages/company/administrators.vue' /* webpackChunkName: "pages/company/administrators" */))
const _e335ec1e = () => interopDefault(import('../pages/company/employees.vue' /* webpackChunkName: "pages/company/employees" */))
const _e7534b10 = () => interopDefault(import('../pages/company/export.vue' /* webpackChunkName: "pages/company/export" */))
const _6549ded0 = () => interopDefault(import('../pages/company/groups.vue' /* webpackChunkName: "pages/company/groups" */))
const _72e074b7 = () => interopDefault(import('../pages/company/non-working-days.vue' /* webpackChunkName: "pages/company/non-working-days" */))
const _620d5867 = () => interopDefault(import('../pages/company/settings.vue' /* webpackChunkName: "pages/company/settings" */))
const _05a3e203 = () => interopDefault(import('../pages/register/company.vue' /* webpackChunkName: "pages/register/company" */))
const _2ce72189 = () => interopDefault(import('../pages/register/personal-information.vue' /* webpackChunkName: "pages/register/personal-information" */))
const _79e22bc7 = () => interopDefault(import('../pages/register/team.vue' /* webpackChunkName: "pages/register/team" */))
const _5881e205 = () => interopDefault(import('../pages/user/account-settings.vue' /* webpackChunkName: "pages/user/account-settings" */))
const _26cef7fc = () => interopDefault(import('../pages/user/approvers.vue' /* webpackChunkName: "pages/user/approvers" */))
const _2f54583b = () => interopDefault(import('../pages/user/days-off.vue' /* webpackChunkName: "pages/user/days-off" */))
const _167849f6 = () => interopDefault(import('../pages/user/export.vue' /* webpackChunkName: "pages/user/export" */))
const _7a10b73e = () => interopDefault(import('../pages/user/personal-information.vue' /* webpackChunkName: "pages/user/personal-information" */))
const _83cbac30 = () => interopDefault(import('../pages/user/_id.vue' /* webpackChunkName: "pages/user/_id" */))
const _caa1d38c = () => interopDefault(import('../pages/user/_id/account-settings.vue' /* webpackChunkName: "pages/user/_id/account-settings" */))
const _21eae567 = () => interopDefault(import('../pages/user/_id/approvers.vue' /* webpackChunkName: "pages/user/_id/approvers" */))
const _04c0dd20 = () => interopDefault(import('../pages/user/_id/days-off.vue' /* webpackChunkName: "pages/user/_id/days-off" */))
const _e58b672a = () => interopDefault(import('../pages/user/_id/export.vue' /* webpackChunkName: "pages/user/_id/export" */))
const _0655b616 = () => interopDefault(import('../pages/user/_id/personal-information.vue' /* webpackChunkName: "pages/user/_id/personal-information" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accept-invitation",
    component: _cb60e508,
    name: "accept-invitation___hu___default"
  }, {
    path: "/change-password",
    component: _b308aed4,
    name: "change-password___hu___default"
  }, {
    path: "/dashboard",
    component: _8e46ecdc,
    name: "dashboard___hu___default"
  }, {
    path: "/days-off",
    component: _324c3c2a,
    name: "days-off___hu___default"
  }, {
    path: "/en",
    component: _1c2e9bd0,
    name: "index___en"
  }, {
    path: "/forgot-password",
    component: _aac667fa,
    name: "forgot-password___hu___default"
  }, {
    path: "/help",
    component: _595a899a,
    name: "help___hu___default"
  }, {
    path: "/hu",
    component: _1c2e9bd0,
    name: "index___hu"
  }, {
    path: "/login",
    component: _34538232,
    name: "login___hu___default"
  }, {
    path: "/logout",
    component: _2202a67c,
    name: "logout___hu___default"
  }, {
    path: "/privacy-policy",
    component: _109b28ee,
    name: "privacy-policy___hu___default"
  }, {
    path: "/register",
    component: _c149c150,
    name: "register___hu___default"
  }, {
    path: "/company/administrators",
    component: _04ead90a,
    name: "company-administrators___hu___default"
  }, {
    path: "/company/employees",
    component: _e335ec1e,
    name: "company-employees___hu___default"
  }, {
    path: "/company/export",
    component: _e7534b10,
    name: "company-export___hu___default"
  }, {
    path: "/company/groups",
    component: _6549ded0,
    name: "company-groups___hu___default"
  }, {
    path: "/company/non-working-days",
    component: _72e074b7,
    name: "company-non-working-days___hu___default"
  }, {
    path: "/company/settings",
    component: _620d5867,
    name: "company-settings___hu___default"
  }, {
    path: "/en/accept-invitation",
    component: _cb60e508,
    name: "accept-invitation___en"
  }, {
    path: "/en/change-password",
    component: _b308aed4,
    name: "change-password___en"
  }, {
    path: "/en/dashboard",
    component: _8e46ecdc,
    name: "dashboard___en"
  }, {
    path: "/en/days-off",
    component: _324c3c2a,
    name: "days-off___en"
  }, {
    path: "/en/forgot-password",
    component: _aac667fa,
    name: "forgot-password___en"
  }, {
    path: "/en/help",
    component: _595a899a,
    name: "help___en"
  }, {
    path: "/en/login",
    component: _34538232,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _2202a67c,
    name: "logout___en"
  }, {
    path: "/en/privacy-policy",
    component: _109b28ee,
    name: "privacy-policy___en"
  }, {
    path: "/en/register",
    component: _c149c150,
    name: "register___en"
  }, {
    path: "/hu/accept-invitation",
    component: _cb60e508,
    name: "accept-invitation___hu"
  }, {
    path: "/hu/change-password",
    component: _b308aed4,
    name: "change-password___hu"
  }, {
    path: "/hu/dashboard",
    component: _8e46ecdc,
    name: "dashboard___hu"
  }, {
    path: "/hu/days-off",
    component: _324c3c2a,
    name: "days-off___hu"
  }, {
    path: "/hu/forgot-password",
    component: _aac667fa,
    name: "forgot-password___hu"
  }, {
    path: "/hu/help",
    component: _595a899a,
    name: "help___hu"
  }, {
    path: "/hu/login",
    component: _34538232,
    name: "login___hu"
  }, {
    path: "/hu/logout",
    component: _2202a67c,
    name: "logout___hu"
  }, {
    path: "/hu/privacy-policy",
    component: _109b28ee,
    name: "privacy-policy___hu"
  }, {
    path: "/hu/register",
    component: _c149c150,
    name: "register___hu"
  }, {
    path: "/register/company",
    component: _05a3e203,
    name: "register-company___hu___default"
  }, {
    path: "/register/personal-information",
    component: _2ce72189,
    name: "register-personal-information___hu___default"
  }, {
    path: "/register/team",
    component: _79e22bc7,
    name: "register-team___hu___default"
  }, {
    path: "/user/account-settings",
    component: _5881e205,
    name: "user-account-settings___hu___default"
  }, {
    path: "/user/approvers",
    component: _26cef7fc,
    name: "user-approvers___hu___default"
  }, {
    path: "/user/days-off",
    component: _2f54583b,
    name: "user-days-off___hu___default"
  }, {
    path: "/user/export",
    component: _167849f6,
    name: "user-export___hu___default"
  }, {
    path: "/user/personal-information",
    component: _7a10b73e,
    name: "user-personal-information___hu___default"
  }, {
    path: "/en/company/administrators",
    component: _04ead90a,
    name: "company-administrators___en"
  }, {
    path: "/en/company/employees",
    component: _e335ec1e,
    name: "company-employees___en"
  }, {
    path: "/en/company/export",
    component: _e7534b10,
    name: "company-export___en"
  }, {
    path: "/en/company/groups",
    component: _6549ded0,
    name: "company-groups___en"
  }, {
    path: "/en/company/non-working-days",
    component: _72e074b7,
    name: "company-non-working-days___en"
  }, {
    path: "/en/company/settings",
    component: _620d5867,
    name: "company-settings___en"
  }, {
    path: "/en/register/company",
    component: _05a3e203,
    name: "register-company___en"
  }, {
    path: "/en/register/personal-information",
    component: _2ce72189,
    name: "register-personal-information___en"
  }, {
    path: "/en/register/team",
    component: _79e22bc7,
    name: "register-team___en"
  }, {
    path: "/en/user/account-settings",
    component: _5881e205,
    name: "user-account-settings___en"
  }, {
    path: "/en/user/approvers",
    component: _26cef7fc,
    name: "user-approvers___en"
  }, {
    path: "/en/user/days-off",
    component: _2f54583b,
    name: "user-days-off___en"
  }, {
    path: "/en/user/export",
    component: _167849f6,
    name: "user-export___en"
  }, {
    path: "/en/user/personal-information",
    component: _7a10b73e,
    name: "user-personal-information___en"
  }, {
    path: "/hu/company/administrators",
    component: _04ead90a,
    name: "company-administrators___hu"
  }, {
    path: "/hu/company/employees",
    component: _e335ec1e,
    name: "company-employees___hu"
  }, {
    path: "/hu/company/export",
    component: _e7534b10,
    name: "company-export___hu"
  }, {
    path: "/hu/company/groups",
    component: _6549ded0,
    name: "company-groups___hu"
  }, {
    path: "/hu/company/non-working-days",
    component: _72e074b7,
    name: "company-non-working-days___hu"
  }, {
    path: "/hu/company/settings",
    component: _620d5867,
    name: "company-settings___hu"
  }, {
    path: "/hu/register/company",
    component: _05a3e203,
    name: "register-company___hu"
  }, {
    path: "/hu/register/personal-information",
    component: _2ce72189,
    name: "register-personal-information___hu"
  }, {
    path: "/hu/register/team",
    component: _79e22bc7,
    name: "register-team___hu"
  }, {
    path: "/hu/user/account-settings",
    component: _5881e205,
    name: "user-account-settings___hu"
  }, {
    path: "/hu/user/approvers",
    component: _26cef7fc,
    name: "user-approvers___hu"
  }, {
    path: "/hu/user/days-off",
    component: _2f54583b,
    name: "user-days-off___hu"
  }, {
    path: "/hu/user/export",
    component: _167849f6,
    name: "user-export___hu"
  }, {
    path: "/hu/user/personal-information",
    component: _7a10b73e,
    name: "user-personal-information___hu"
  }, {
    path: "/en/user/:id?",
    component: _83cbac30,
    name: "user-id___en",
    children: [{
      path: "account-settings",
      component: _caa1d38c,
      name: "user-id-account-settings___en"
    }, {
      path: "approvers",
      component: _21eae567,
      name: "user-id-approvers___en"
    }, {
      path: "days-off",
      component: _04c0dd20,
      name: "user-id-days-off___en"
    }, {
      path: "export",
      component: _e58b672a,
      name: "user-id-export___en"
    }, {
      path: "personal-information",
      component: _0655b616,
      name: "user-id-personal-information___en"
    }]
  }, {
    path: "/hu/user/:id?",
    component: _83cbac30,
    name: "user-id___hu",
    children: [{
      path: "account-settings",
      component: _caa1d38c,
      name: "user-id-account-settings___hu"
    }, {
      path: "approvers",
      component: _21eae567,
      name: "user-id-approvers___hu"
    }, {
      path: "days-off",
      component: _04c0dd20,
      name: "user-id-days-off___hu"
    }, {
      path: "export",
      component: _e58b672a,
      name: "user-id-export___hu"
    }, {
      path: "personal-information",
      component: _0655b616,
      name: "user-id-personal-information___hu"
    }]
  }, {
    path: "/user/:id?",
    component: _83cbac30,
    name: "user-id___hu___default",
    children: [{
      path: "account-settings",
      component: _caa1d38c,
      name: "user-id-account-settings___hu___default"
    }, {
      path: "approvers",
      component: _21eae567,
      name: "user-id-approvers___hu___default"
    }, {
      path: "days-off",
      component: _04c0dd20,
      name: "user-id-days-off___hu___default"
    }, {
      path: "export",
      component: _e58b672a,
      name: "user-id-export___hu___default"
    }, {
      path: "personal-information",
      component: _0655b616,
      name: "user-id-personal-information___hu___default"
    }]
  }, {
    path: "/",
    component: _1c2e9bd0,
    name: "index___hu___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
